import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import HomePage from "./HomePage";
// src/App.js
import React from "react";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<HomePage />} />
				{/* Define other routes as needed */}
			</Routes>
		</Router>
	);
}

export default App;
