// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Navigation, Pagination } from "swiper/modules";
import {
	FaBars,
	FaChartLine,
	FaClock,
	FaEnvelope,
	FaFacebookF,
	FaFootballBall,
	FaHeart,
	FaInstagram,
	FaLinkedinIn,
	FaMapMarkerAlt,
	FaPhone,
	FaPlayCircle,
	FaQuoteLeft,
	FaShieldAlt,
	FaShoppingCart,
	FaTimes,
	FaTrophy,
	FaTshirt,
	FaTwitter,
	FaUserFriends,
	FaUsers,
	FaYoutube,
} from "./Icons";
// src/components/HomePage.js
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Link } from "react-router-dom";
import SwiperCore from "swiper";

// Import Swiper styles

// Initialize Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const HomePage = () => {
	return (
		<div className="flex flex-col min-h-screen bg-background">
			<Header />
			<Navbar />
			<VideoSlider />
			<WhatWeOffer />
			<ScrollingVideoSection />
			<Testimonials />
			<Journey />
			<Footer />
		</div>
	);
};

export default HomePage;

// Header Component
const Header = () => {
	return (
		<div className="bg-gray-100 text-gray-700">
			<div className="container mx-auto px-4 py-2 flex flex-col md:flex-row justify-between items-center">
				{/* Social Links */}
				<div className="flex space-x-4 mb-2 md:mb-0">
					<a
						href="https://facebook.com"
						target="_blank"
						rel="noopener noreferrer"
						className="text-primary hover:text-secondary"
					>
						<FaFacebookF />
					</a>
					<a
						href="https://twitter.com"
						target="_blank"
						rel="noopener noreferrer"
						className="text-primary hover:text-secondary"
					>
						<FaTwitter />
					</a>
					<a
						href="https://instagram.com"
						target="_blank"
						rel="noopener noreferrer"
						className="text-primary hover:text-secondary"
					>
						<FaInstagram />
					</a>
					<a
						href="https://linkedin.com"
						target="_blank"
						rel="noopener noreferrer"
						className="text-primary hover:text-secondary"
					>
						<FaLinkedinIn />
					</a>
					<a
						href="https://youtube.com"
						target="_blank"
						rel="noopener noreferrer"
						className="text-primary hover:text-secondary"
					>
						<FaYoutube />
					</a>
				</div>
				{/* Top Links */}
			</div>
		</div>
	);
};

// Dropdown Component
// const Dropdown = ({ label, options }) => {
// 	const [isOpen, setIsOpen] = useState(false);

// 	return (
// 		<div className="relative inline-block text-left">
// 			<div>
// 				<button
// 					onClick={() => setIsOpen(!isOpen)}
// 					type="button"
// 					className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
// 					id="menu-button"
// 					aria-expanded={isOpen}
// 					aria-haspopup="true"
// 				>
// 					{label}
// 					<FaChevronDown className="ml-2" />
// 				</button>
// 			</div>
// 			{/* Dropdown panel */}
// 			{isOpen && (
// 				<div
// 					className="origin-top-right absolute right-0 mt-2 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
// 					role="menu"
// 					aria-orientation="vertical"
// 					aria-labelledby="menu-button"
// 				>
// 					<div className="py-1" role="none">
// 						{options.map((option, index) => (
// 							<Link
// 								key={index}
// 								to="#"
// 								className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
// 								role="menuitem"
// 								onClick={() => setIsOpen(false)}
// 							>
// 								{option}
// 							</Link>
// 						))}
// 					</div>
// 				</div>
// 			)}
// 		</div>
// 	);
// };

// Navbar Component
const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);

	// Define your navigation items here
	const navItems = [
		{ title: "Home", link: "/" },
		{ title: "Matches", link: "/matches" },
		{ title: "Players", link: "/players" },
		{ title: "Fixtures", link: "/fixtures" },
		{ title: "Point Table", link: "/point-table" },
		{ title: "News", link: "/news" },
		{ title: "Features", link: "/features" },
		{ title: "Contact", link: "/contact" },
		{ title: "About", link: "/about" },
	];

	return (
		<nav className="bg-primary text-white">
			<div className="container mx-auto px-4">
				<div className="flex justify-between items-center h-16">
					{/* Left Logo */}
					<div className="flex-shrink-0">
						<Link to="/">
							<img
								src="/Logo.png"
								alt="Left Logo"
								className="h-10 w-10 rounded-full"
							/>
						</Link>
					</div>

					{/* Center Nav Items */}
					<div className="hidden md:block">
						<div className="ml-10 flex items-baseline space-x-4">
							{navItems.map((item, index) => (
								<Link
									key={index}
									to={item.link}
									className="px-3 py-2 rounded-md text-sm font-medium hover:bg-secondary hover:text-primary transition duration-300"
								>
									{item.title}
								</Link>
							))}
						</div>
					</div>

					{/* Right Logo */}
					<div className="flex-shrink-0">
						<Link to="/">
							<img
								src="/Logo.png"
								alt="Right Logo"
								className="h-10 w-10 rounded-full"
							/>
						</Link>
					</div>

					{/* Mobile menu button */}
					<div className="md:hidden">
						<button
							onClick={() => setIsOpen(!isOpen)}
							type="button"
							className="text-white hover:text-gray-300 focus:outline-none focus:text-gray-300"
							aria-controls="mobile-menu"
							aria-expanded={isOpen}
						>
							{isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
						</button>
					</div>
				</div>
			</div>

			{/* Mobile Menu */}
			{isOpen && (
				<div className="md:hidden bg-primary" id="mobile-menu">
					<div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
						{navItems.map((item, index) => (
							<Link
								key={index}
								to={item.link}
								className="block px-3 py-2 rounded-md text-base font-medium hover:bg-secondary hover:text-primary transition duration-300"
								onClick={() => setIsOpen(false)} // Close menu on click
							>
								{item.title}
							</Link>
						))}
					</div>
				</div>
			)}
		</nav>
	);
};

// VideoSlider Component
const VideoSlider = () => {
	const slides = [
		{
			videoSrc: "https://www.w3schools.com/howto/rain.mp4", // Sample video
			title: "Football Evolution",
			buttonText: "What We Offer",
			buttonLink: "#",
		},
		{
			videoSrc: "https://www.w3schools.com/howto/rain.mp4", // Sample video
			title: "Football Evolution",
			buttonText: "What We Offer",
			buttonLink: "#",
		},
	];

	return (
		<div className="relative">
			<Swiper
				spaceBetween={0}
				slidesPerView={1}
				loop={true}
				autoplay={{ delay: 5000 }}
				pagination={{ clickable: true }}
				navigation={true}
			>
				{slides.map((slide, index) => (
					<SwiperSlide key={index}>
						<div className="relative h-screen">
							<video
								className="w-full h-full object-cover"
								src={slide.videoSrc}
								autoPlay
								muted
								loop
								playsInline
							/>
							{/* Overlay */}
							<div className="absolute inset-0 bg-black opacity-40"></div>
							{/* Overlay Content */}
							<div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white px-4">
								<h1 className="text-4xl md:text-6xl font-bold mb-4 animate-fadeIn">
									{slide.title}
								</h1>
								<a
									href={slide.buttonLink}
									className="px-6 py-3 bg-secondary text-primary font-semibold rounded-md hover:bg-yellow-600 transition duration-300 animate-bounce"
								>
									{slide.buttonText}
								</a>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

// WhatWeOffer Component
const WhatWeOffer = () => {
	const offers = [
		{
			icon: <FaFootballBall size={40} className="text-primary" />,
			title: "Premium Quality Gear",
			description:
				"Top-notch equipment to enhance your performance on the field.",
			image: "https://source.unsplash.com/random/400x300/?football,gear",
		},
		{
			icon: <FaTshirt size={40} className="text-primary" />,
			title: "Stylish Apparel",
			description: "Comfortable and trendy sportswear for every player.",
			image: "https://source.unsplash.com/random/400x300/?football,apparel",
		},
		{
			icon: <FaShoppingCart size={40} className="text-primary" />,
			title: "Online Store",
			description:
				"Easy access to our wide range of products at your fingertips.",
			image: "https://source.unsplash.com/random/400x300/?shopping,store",
		},
		{
			icon: <FaHeart size={40} className="text-primary" />,
			title: "Community Support",
			description:
				"Join our community and stay connected with fellow enthusiasts.",
			image: "https://source.unsplash.com/random/400x300/?community,support",
		},
		{
			icon: <FaUserFriends size={40} className="text-primary" />,
			title: "Team Management",
			description: "Tools and resources to manage your team effectively.",
			image: "https://source.unsplash.com/random/400x300/?team,management",
		},
		{
			icon: <FaShieldAlt size={40} className="text-primary" />,
			title: "Safety First",
			description: "Ensuring safety with our high-quality protective gear.",
			image: "https://source.unsplash.com/random/400x300/?safety,gear",
		},
	];

	return (
		<section className="bg-background py-12">
			<div className="container mx-auto px-4">
				{/* Section Title */}
				<div className="text-center mb-8">
					<h2 className="text-3xl font-semibold text-primary">What We Offer</h2>
					<p className="mt-2 text-gray-600">
						Discover the range of services and products we provide to elevate
						your game.
					</p>
				</div>
				{/* Offer Cards */}
				<div className="flex flex-wrap -mx-4">
					{offers.map((offer, index) => (
						<div key={index} className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
							<div className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300">
								<img
									src={offer.image}
									alt={offer.title}
									className="w-full h-48 object-cover"
								/>
								<div className="p-6">
									<div className="flex items-center justify-center mb-4">
										{offer.icon}
									</div>
									<h3 className="text-xl font-semibold text-center mb-2">
										{offer.title}
									</h3>
									<p className="text-gray-600 text-center">
										{offer.description}
									</p>
								</div>
							</div>
						</div>
					))}
				</div>
				{/* "What We Offer" Button */}
				<div className="text-center">
					<a
						href="/"
						className="inline-block px-6 py-3 bg-secondary text-primary font-semibold rounded-md hover:bg-yellow-600 transition duration-300"
					>
						Learn More
					</a>
				</div>
			</div>
		</section>
	);
};

// ScrollingVideoSection Component
const ScrollingVideoSection = () => {
	const latestVideos = [
		{
			title: "Mastering the Midfield",
			thumbnail:
				"https://source.unsplash.com/random/400x300/?football,midfield",
			link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ", // Sample link
		},
		{
			title: "Defensive Strategies",
			thumbnail: "https://source.unsplash.com/random/400x300/?football,defense",
			link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
		},
		{
			title: "Goalkeeping Techniques",
			thumbnail:
				"https://source.unsplash.com/random/400x300/?football,goalkeeper",
			link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
		},
		{
			title: "Offensive Tactics",
			thumbnail: "https://source.unsplash.com/random/400x300/?football,offense",
			link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
		},
	];

	return (
		<section className="relative h-screen overflow-hidden">
			{/* Background Image with Parallax Effect */}
			<div
				className="absolute top-0 left-0 w-full h-full bg-cover bg-center bg-fixed"
				style={{
					backgroundImage: "/wallpaperflare.com_wallpaper.jpg",
				}}
			>
				<img src="/wallpaperflare.com_wallpaper.jpg" alt="" />
				{/* Optional Overlay */}
				<div className="absolute inset-0 bg-black opacity-30"></div>
			</div>

			{/* Fixed Image or Content */}
			<div className="relative z-10 flex flex-col items-center justify-center h-full">
				<LatestVideos videos={latestVideos} />
			</div>
		</section>
	);
};

// LatestVideos Component
const LatestVideos = ({ videos }) => {
	return (
		<div className="bg-white bg-opacity-90 p-6 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
			<h3 className="text-2xl font-semibold text-center mb-4">Latest Videos</h3>
			<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
				{videos.map((video, index) => (
					<div key={index} className="relative">
						<a href={video.link} target="_blank" rel="noopener noreferrer">
							<img
								src={video.thumbnail}
								alt={video.title}
								className="w-full h-40 object-cover rounded-lg"
							/>
							<FaPlayCircle className="absolute inset-0 m-auto text-4xl text-white opacity-75 hover:opacity-100 transition-opacity duration-300" />
						</a>
						<h4 className="mt-2 text-lg font-medium text-center">
							{video.title}
						</h4>
					</div>
				))}
			</div>
		</div>
	);
};

// Testimonials Component
const Testimonials = () => {
	const testimonials = [
		{
			name: "John Doe",
			title: "Professional Footballer",
			photo: "https://source.unsplash.com/random/100x100/?person,1",
			message:
				"This platform has revolutionized the way I train and manage my performance on the field.",
		},
		{
			name: "Jane Smith",
			title: "Sports Coach",
			photo: "https://source.unsplash.com/random/100x100/?person,2",
			message:
				"The resources and community support provided here are unparalleled. Highly recommended!",
		},
		{
			name: "Mike Johnson",
			title: "Football Analyst",
			photo: "https://source.unsplash.com/random/100x100/?person,3",
			message:
				"An essential tool for anyone serious about improving their game and staying updated with the latest trends.",
		},
	];

	return (
		<section className="bg-background py-12">
			<div className="container mx-auto px-4">
				{/* Section Title */}
				<div className="text-center mb-8">
					<h2 className="text-3xl font-semibold text-primary">
						What the Pros Say
					</h2>
					<p className="mt-2 text-gray-600">
						Hear from the professionals who trust us.
					</p>
				</div>
				{/* Testimonials Grid */}
				<div className="flex flex-wrap -mx-4">
					{testimonials.map((testimonial, index) => (
						<div key={index} className="w-full md:w-1/3 px-4 mb-8">
							<div className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center">
								<FaQuoteLeft className="text-secondary text-3xl mb-4" />
								<p className="text-gray-700 mb-6">{testimonial.message}</p>
								<img
									src={testimonial.photo}
									alt={testimonial.name}
									className="w-24 h-24 rounded-full mb-4 object-cover"
								/>
								<h4 className="text-xl font-semibold">{testimonial.name}</h4>
								<span className="text-gray-500">{testimonial.title}</span>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

// Journey Component
const Journey = () => {
	const milestones = [
		{
			icon: <FaClock size={40} className="text-primary" />,
			title: "Founded",
			description:
				"Established in 2010, beginning our journey in local leagues.",
		},
		{
			icon: <FaMapMarkerAlt size={40} className="text-primary" />,
			title: "First Championship",
			description:
				"Won our first championship in 2012, marking our rise in the sports community.",
		},
		{
			icon: <FaTrophy size={40} className="text-primary" />,
			title: "International Recognition",
			description:
				"Gained international recognition and expanded our reach globally.",
		},
		{
			icon: <FaUsers size={40} className="text-primary" />,
			title: "Community Engagement",
			description:
				"Started various community programs to engage and support local talents.",
		},
		{
			icon: <FaChartLine size={40} className="text-primary" />,
			title: "Continuous Growth",
			description:
				"Consistently growing our team and resources to achieve greater heights.",
		},
	];

	return (
		<section className="bg-white py-12">
			<div className="container mx-auto px-4">
				{/* Section Title */}
				<div className="text-center mb-8">
					<h2 className="text-3xl font-semibold text-primary">Our Journey</h2>
					<p className="mt-2 text-gray-600">
						A look back at our milestones and achievements.
					</p>
				</div>
				{/* Milestones Timeline */}
				<div className="flex flex-col md:flex-row md:space-x-6">
					{milestones.map((milestone, index) => (
						<div
							key={index}
							className="flex flex-col items-center mb-8 md:mb-0"
						>
							<div className="bg-secondary p-4 rounded-full mb-4">
								{milestone.icon}
							</div>
							<h3 className="text-xl font-semibold text-center mb-2">
								{milestone.title}
							</h3>
							<p className="text-gray-600 text-center">
								{milestone.description}
							</p>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

// Footer Component
const Footer = () => {
	// const instagramImages = [
	// 	"https://source.unsplash.com/random/100x100/?football,instagram1",
	// 	"https://source.unsplash.com/random/100x100/?football,instagram2",
	// 	"https://source.unsplash.com/random/100x100/?football,instagram3",
	// 	"https://source.unsplash.com/random/100x100/?football,instagram4",
	// 	"https://source.unsplash.com/random/100x100/?football,instagram5",
	// 	"https://source.unsplash.com/random/100x100/?football,instagram6",
	// ];

	return (
		<footer className="bg-primary text-white">
			<div className="container mx-auto px-4 py-12">
				<div className="flex flex-wrap -mx-4">
					{/* About Widget */}
					<div className="w-full md:w-1/4 px-4 mb-8">
						<div>
							<img
								src="https://via.placeholder.com/150x50"
								alt="Logo"
								className="mb-4"
							/>
							<p className="mb-4">
								Fusce ac pharetra urna. Duis non lacus sit amet lacus interdum
								facilisis sed non est ut mi metus semper.
							</p>
							<address>
								<ul className="space-y-2">
									<li className="flex items-center">
										<FaMapMarkerAlt className="mr-2" /> 4700 Millenia Blvd #175,
										Orlando, FL 32839, USA
									</li>
									<li className="flex items-center">
										<FaPhone className="mr-2" /> +1 321 2345 678-7
									</li>
									<li className="flex items-center">
										<FaEnvelope className="mr-2" /> info@soccer.com
									</li>
								</ul>
							</address>
						</div>
					</div>
					{/* About Soccer */}
					<div className="w-full md:w-1/4 px-4 mb-8">
						<div>
							<h4 className="text-xl font-semibold mb-4">About Soccer</h4>
							<ul className="space-y-2">
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> About Club
									</Link>
								</li>
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> Matches Schedules
									</Link>
								</li>
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> Groups Table
									</Link>
								</li>
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> Teams
									</Link>
								</li>
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> Statistics
									</Link>
								</li>
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> Qualifiers
									</Link>
								</li>
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> Ticket Bookings
									</Link>
								</li>
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> Shoes
									</Link>
								</li>
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> T-Shirts
									</Link>
								</li>
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> Sports Wear
									</Link>
								</li>
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> Accessories
									</Link>
								</li>
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> Shop
									</Link>
								</li>
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> Contact Us
									</Link>
								</li>
								<li>
									<Link to="/" className="hover:underline flex items-center">
										<span className="mr-2">&raquo;</span> Media Room
									</Link>
								</li>
							</ul>
						</div>
					</div>
					{/* Recent Instagram */}
					<div className="w-full md:w-1/4 px-4 mb-8">
						<div>
							<h4 className="text-xl font-semibold mb-4">Recent Instagram</h4>
							<ul className="flex flex-wrap space-x-2">
								{/* {instagramImages.map((img, index) => (
									<li key={index} className="w-1/2 mb-2">
										<img
											src={img}
											alt={`Instagram ${index + 1}`}
											className="w-full h-20 object-cover rounded"
										/>
									</li> */}
								{/* ))} */}
							</ul>
						</div>
					</div>
					{/* Get Updated */}
					<div className="w-full md:w-1/4 px-4 mb-8">
						<div>
							<h4 className="text-xl font-semibold mb-4">Get Updated</h4>
							<p className="mb-4">
								Sign up to Get Updated & latest offers with our Newsletter.
							</p>
							<form className="flex flex-col space-y-2">
								<input
									type="text"
									placeholder="Your Name"
									className="px-4 py-2 rounded-md text-gray-700"
								/>
								<input
									type="email"
									placeholder="Your Email Address"
									className="px-4 py-2 rounded-md text-gray-700"
								/>
								<button
									type="submit"
									className="px-4 py-2 bg-secondary text-primary font-semibold rounded-md hover:bg-yellow-600 transition duration-300"
								>
									Subscribe
								</button>
								<p className="text-xs text-gray-400">We respect your privacy</p>
							</form>
						</div>
					</div>
				</div>
				{/* Bottom Footer */}
				<div className="flex flex-col md:flex-row justify-between items-center border-t border-gray-700 pt-4">
					<p className="text-sm">
						&copy; 2024 All Rights Reserved by Sports. Design & Developed By:{" "}
						<a href="/" className="text-secondary hover:underline">
							Football Evolution
						</a>
					</p>
					<ul className="flex space-x-4 mt-2 md:mt-0">
						<li>
							<Link to="/" className="hover:underline">
								Home
							</Link>
						</li>
						<li>
							<Link to="/" className="hover:underline">
								Players
							</Link>
						</li>
						<li>
							<Link to="/" className="hover:underline">
								Fixtures
							</Link>
						</li>
						<li>
							<Link to="/" className="hover:underline">
								Point Table
							</Link>
						</li>
						<li>
							<Link to="/" className="hover:underline">
								Tickets
							</Link>
						</li>
						<li>
							<Link to="/" className="hover:underline">
								Contact
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
};
